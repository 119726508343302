<template>
    <div style="width: 100%;height: 100%;overflow: hidden">
        <div v-for="(item,index) in expertLink" :key="index">
            <img :src="item.url" class="low">
        </div>
        <div style="display: flex">
            <div style="width: 100%;height: auto;overflow: hidden" >
                <div style="width: 50%;height:100%;float: left;background: #13138b">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection11.jpg" style="width: 100%;">
                </div>
                <div class="old" style="width: 50%;float: left">
                    <Introductionlogin></Introductionlogin>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Introductionlogin from "./components/Introductionlogin";
export default {
    components: {
        Introductionlogin

    },

    computed: {

    },
    data () {
        return {
            expertLink:[
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection1.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection2.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection3.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection4.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection5.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection6.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection7.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection8.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertConnection9.jpeg"},

            ]
        }
    },
    mounted:function(){

    },
    created: function(){
    
    },
    methods:{

    }
}
</script>
<style scoped>
.low {
    width: 100%;
    height: 100%;
}

.old {
    width: 100%;
    height: 100%;
    /*background: url("../../assets/imgs/index/expertConnection_img/expertConnection12.jpg");background-size: 100%;*/
    background: #FFFFFF;
}
</style>
