<template>
    <div>
        <div v-if="showTime == true">
            <div style="display: flex;justify-content: center;line-height: 80px;margin-left: 110px"><h1>欢迎您注册百工宝</h1></div>
            <div style="display: flex;justify-content: center;margin-left: 100px">
                <div :class="['name_lits', { 'active': Options === 1 }]" @click="changeTab(1)" >个人注册</div>
                <div style="font-weight: bold;margin: 5px 10px 0 10px">|</div>
                <div :class="['name_lits', { 'active': Options === 2 }]" @click="changeTab(2)">企业注册</div>
            </div>
            <!--  个人注册  -->
            <div  style="display: flex;justify-content: center">
                <div style="width: 50%;margin-top: 10px">
                    <!-- Options === 1 为个人注册  Options === 2 为企业注册    -->
                    <el-form :model="ruleForm"  status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-radio-group v-if="Options === 2" v-model="organizationType" size="small" style="margin-top: 10px;margin-left: 170px;margin-bottom: 10px;">
                            <el-radio-button label="1">企业</el-radio-button>
                            <el-radio-button label="2">机关</el-radio-button>
                            <el-radio-button label="3">社团</el-radio-button>
                            <el-radio-button label="4">工作室</el-radio-button>
                        </el-radio-group>
                        <el-radio-group v-if="Options === 1"  size="small" style="margin-top: 10px;margin-bottom: 10px;">
                            <div style="height: 32px;"></div>
                        </el-radio-group>
                        <el-form-item label="昵称" prop="nickName">
                            <el-input v-model="ruleForm.nickName" maxlength="10" show-word-limit ></el-input>
                        </el-form-item>
                        <!--                    <el-form-item label="登录账号" prop="accoun">-->
                        <!--                        <el-input v-model="ruleForm.accoun" maxlength="15"></el-input>-->
                        <!--                    </el-form-item>-->
                        <!--                    <el-form-item label="登录密码" prop="pass">-->
                        <!--                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>-->
                        <!--                    </el-form-item>-->
                        <!--                    <el-form-item label="确认登录密码" prop="checkPass">-->
                        <!--                        <el-input type="password"  v-model="ruleForm.checkPass" autocomplete="off"></el-input>-->
                        <!--                    </el-form-item>-->
                        <el-form-item v-if="Options === 2" label="注册人姓名" prop="regName">
                            <el-input v-model="ruleForm.regName" :maxlength="15"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="ruleForm.phone" type="text" :maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="code">
                            <el-input  type="text" v-model="ruleForm.code" :maxlength="6" >
                                <template slot="append">
                                    <div style="width: 100%;height: 30px;line-height: 30px;border: 0;background: #F5F7FA;cursor: pointer" @click="getCode"  :disabled="disabled">{{ codestring }}</div>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item v-if="Options === 1" label="专业领域" prop="major">
                            <el-cascader   v-model="ruleForm.major"  :options="type_list"  ></el-cascader>
                        </el-form-item>
                        <el-form-item v-if="Options === 2" label="行业" prop="value">
                            <el-cascader   v-model="ruleForm.value"  :options="type_list"  ></el-cascader>
                        </el-form-item>
                        <el-form-item label="上传头像" prop="headUrl" >
                            <el-upload class="avatar-uploader" action="https:oss.baigongbao.com" :show-file-list="false" :http-request="upload_img">
                                <img v-if="headUrl" :src="headUrl" class="avatar">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item style="display: flex;justify-content: center;">
                            <el-button style="width: 200px;" type="primary" @click="submitForm('ruleForm')">进入</el-button>
                            <div>已有账号？<span style="color: #1C7EFF;cursor: pointer" @click="account">直接登录>></span></div>
                        </el-form-item>

                    </el-form>

                </div>

            </div>
        </div>
        <div v-if="showTime == false" style="width: 100%;height: 100%;background: #fff;">
            <div style="width: 100%;line-height: 60px;text-align: center;font-size: 24px;">内测中请扫码注册</div>
            <div style="width: 100%;height: calc(100% - 80px);">
                <div class="centered" style="width: 300px;height: 300px;margin: 0 auto;margin-top: 50px;">
                    <img style="width: 100%;height: 100%;" src="https://oss.baigongbao.com/img/xcxm.jpg">
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {


    },

    computed: {

    },
    data () {
        // var validatePass = (rule, value, callback) => {
        //     if (value === '') {
        //         callback(new Error('请输入密码'));
        //     } else {
        //         if (this.ruleForm.checkPass !== '') {
        //             this.$refs.ruleForm.validateField('checkPass');
        //         }
        //         callback();
        //     }
        // };
        // var validatePass2 = (rule, value, callback) => {
        //     if (value === '') {
        //         callback(new Error('请再次输入密码'));
        //     } else if (value !== this.ruleForm.pass) {
        //         callback(new Error('两次输入密码不一致!'));
        //     } else {
        //         callback();
        //     }
        // };
        return {
            showTime:false,
            Options:1,
            codestring:'获取验证码',
            headUrl: '',
            organizationType:1,
            type_list:[],
            countdownNum:60,
            disabled:false,
            ruleForm: {
                nickName: '',
                // accoun: '',
                // pass: '',
                // checkPass: '',
                phone:'',
                code:'',
                major:'',
                fullname:'',
                value:'',
            },
            rules: {
                // pass: [
                //     { validator: validatePass, trigger: 'blur' }
                // ],
                // checkPass: [
                //     { validator: validatePass2, trigger: 'blur' }
                // ],
                nickName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ],
                // accoun:[
                //     { validator: validatePass7, required: true, message: '请输入登录账号', trigger: 'blur' }
                // ],
                phone:[
                    { pattern: /^1[3|5|6|7|8|9][0-9]\d{8}$/, required: true, message: '请输入手机号', trigger: 'blur',}
                ],
                code:[
                    {pattern: /^[0-9]*$/, required: true, message: '请输入验证码', trigger: 'blur', }
                ],
                major: [
                    { required: true, message: '请输入专业领域', trigger: 'blur', }
                ],
                regName: [
                    {  required: true, message: '请输入姓名', trigger: 'blur',min: 2 }
                ],
                value:[
                    {  required: true, message: '请选择行业', trigger: 'blur' }]
            }
        }

    },
    mounted:function(){
        this.getTypeList()
    },
    methods:{
        account(){
            this.$router.push("/")
        },
        getTypeList(){
            var that = this;
            that.newApi.getTypeList({
                lv: 2,
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },

        // 提交
        submitForm(formName) {
            var that = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    var data = {
                        // accoun:that.accoun,
                        // pwd:that.checkPass,
                        nickName:that.ruleForm.nickName,
                        phone:that.ruleForm.phone,
                        code:that.ruleForm.code,
                        regType:that.ruleForm.Options,
                        major:that.ruleForm.major,
                        headIco:that.ruleForm.headUrl,
                        deviceTypes:1,
                    }
                    if(that.Options == 2) {
                        data.regType= 2;
                        data.regName= that.ruleForm.regName;
                        data.industry= 2;
                        data.organizationType=that.ruleForm.organizationType;
                    }
                }
                that.newApi.userRegister(data).then((res)=>{
                    if (res.isSuccess == 1){
                        that.utils.sus("注册成功");
                        this.$router.push({ path: '/' });
                    }
                })
            });
        },
        // 获取验证码
        getCode(){
            var that = this;
            if (that.ruleForm.phone){
                this.newApi.sendSms({"mobile":that.ruleForm.phone, smsType: 5}).then((ret)=>{
                    that.changecode();
                    that.utils.sus(ret.data);
                })
            }else {
                that.utils.err("手机号不正确");return false;
            }

        },
        // 获取验证码倒计时
        changecode(){
            var that = this;
            if(that.countdownNum == 0) {
                that.disabled = false;
                that.codestring ='获取验证码';
                that.countdownNum = 60;
                return;
            } else {
                that.disabled = true;
                that.codestring = that.countdownNum + '秒后重新发送';
                that.countdownNum--;
            }
            setTimeout(function(){
                that.changecode();
            }, 1000)

        },
        // 验证专业领域
        changeMajor(){
            var re = /^[\u4e00-\u9fa5]+$/;
            if (this.major=='' || !re.test(this.major)){
                this.$message({
                    showClose: true,
                    message: '专业领域不合法',
                    type: 'warning'
                });
                this.majorStatus=false
            }else {
                this.majorStatus=true
            }
        },
        // 上传图片
        upload_img:function(params){
            var file = params.file;
            const that = this;
            this.utils.upload(file,function(url){
                if (!url){
                    return false;
                }
                that.headUrl = url;
            })
        },
        changeTab(tab) {
            this.Options = tab
        },
    }
}
</script>
<style scoped>
.name_lits {
    font-weight: bold;
    font-size: 22px;
    float: left;
    cursor: pointer;
    margin-left: 5px;
}

/deep/ .avatar-uploader .el-upload {
    width: 158px;
    height: 138px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-top:10px;
}
/deep/ .avatar {
    width: 158px;
    height: 138px;
    display: block;
}
/deep/ .el-icon-plus  {
}

.name_lits.active {
    border-bottom: 4px solid #2970FF;
    color: #2970FF;
    padding-bottom: 8px;
}
</style>
